import { Center, Flex, Text } from "@chakra-ui/react"
import { Link } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { UsabilityTestIcon } from "Shared/icons/UsabilityTestIcon"
import { ClockOutlineIcon } from "Shared/icons/untitled-ui/ClockOutlineIcon"
import { AssignmentTag } from "UserCrowd/components/AssignmentTag"
import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { ROUTES } from "../routes"

export const PracticeTestCard: React.FC = () => {
  return (
    <Flex
      direction="column"
      bg="ds.surface.raised.resting"
      shadow="ds.raised"
      rounded="16px"
      gap={4}
      p={4}
      pos="relative"
      mb={4}
    >
      <Flex gap={2} align="center">
        <Center bg="pink.100" boxSize={6} rounded="sm">
          <UsabilityTestIcon color="#D61F69" />
        </Center>

        <Text color="text.primary" fontWeight="bold">
          Test
        </Text>
      </Flex>
      <Flex align="flex-start" gap={2}>
        <Text color="black" fontSize="2xl" fontWeight="bold" px={2}>
          :)
        </Text>
        <Flex direction="column" gap={2}>
          <Flex wrap="wrap" gap={2}>
            <AssignmentTag
              tooltip="It should take approximately 1 minute"
              icon={ClockOutlineIcon}
              label="1 minute"
            />
          </Flex>
          <Text>
            Practice tests help you get familiar with our platform and the types
            of questions you’ll encounter. Its completion is mandatory before
            you can receive real studies, and your response will be reviewed by
            the UserCrowd Team.
          </Text>{" "}
          <Text>
            Note: There is no financial compensation for completing practice
            tests.{" "}
            <Link
              variant="noUnderline"
              whiteSpace="nowrap"
              href="#"
              rel="noopener noreferer"
              target="_blank"
            >
              Learn more
            </Link>
          </Text>
          <Text>TODO: style this card, obviously</Text>
        </Flex>
      </Flex>

      <Flex justify="flex-end">
        <Button
          as={ReactRouterLink}
          variant="primary"
          to={ROUTES.DASHBOARD.START_PRACTICE_TEST.path}
        >
          Participate
        </Button>
      </Flex>
    </Flex>
  )
}
